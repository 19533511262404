<template>
  <div class="main ant-card" :style="{top:(screenHeight/2-230)+'px'}">
    <div class="title">{{ $t('main.title') }}</div>
    <a-form
      id="formLogin"
      class="user-layout-login"
      ref="formLogin"
      :form="form"
      @submit="handleSubmit"
    >

      <a-form-item>
        <a-input
          size="large"
          type="text"
          :placeholder="$t('user.account')"
          v-decorator="[
            'account',
            {rules: [{ required: true, type: 'email', message: $t('user.email.required') }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}
          ]"
        >
          <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }"/>
        </a-input>
      </a-form-item>

      <a-form-item>
        <a-input
          size="large"
          type="password"
          autocomplete="false"
          :placeholder="$t('user.password')"
          v-decorator="[
            'passwd',
            {rules: [{ required: true, message: $t('user.password.required') }], validateTrigger: 'blur'}
          ]"
        >
          <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
        </a-input>
      </a-form-item>
      <a-row>
        <a-col :span="16">
          <a-form-item>
            <a-input
              size="large"
              autocomplete="false"
              :placeholder="$t('user.captcha')"
              v-decorator="[
                'captcha',
                {rules: [{ required: true, message: $t('user.captcha.required') }]}
              ]"
            >
              <a-icon slot="prefix" type="safety-certificate" :style="{ color: 'rgba(0,0,0,.25)' }"/>
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <img :src="captchaUrl" style="height: 36px;margin-left: 16px;position:relative;top:2px;cursor: pointer;" @click="refreshCaptcha">
        </a-col>

        <a-form-item style="margin-top:24px">
          <a-button
            size="large"
            type="primary"
            htmlType="submit"
            class="login-button"
            :loading="state.loginBtn"
            :disabled="state.loginBtn"
          >{{ $t('user.login') }}
          </a-button>
        </a-form-item>

      </a-row>

    </a-form>
    <div>
      <a-checkbox></a-checkbox><span style="margin-left: 8px;">{{ $t('user.readAndAgree') }}<a @click.prevent="showProvicy">{{ $t('user.UserServiceAgreement') }}</a></span>
    </div>
    <div class="user-login-other">
      <router-link class="float-left" :to="{ name: 'findpwd' }">{{ $t('user.password.forget') }}</router-link>
      <router-link class="register" :to="{ name: 'register' }">{{ $t('user.regnow') }}</router-link>
    </div>
  </div>
</template>

<script>
  /* eslint-disable no-unused-vars */

  import md5 from 'md5'
  import axios from 'axios'
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import store from '../../store'
  import Provicy from '@/views/user/Provicy.vue'
  let timer = null

export default {
  components: {
    TwoStepCaptcha
  },
  data () {
    return {
      screenHeight: 0,
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      client: null,
      captchaUrl: null,
      qrUrl: null
    }
  },
  created () {
    this.screenHeight = document.body.clientHeight
    var _this = this
    this.loadImageCaptcha()
    this.initSocketClient()
    timer = setInterval(function () {
       _this.loadImageCaptcha()
    }, 50000)
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    // handler
    refreshQr (clientId, authCode) {
         this.qrUrl = `/user/auth/qr?action=login&clientId=${clientId}&authCode=${authCode}&time=${(new Date()).getTime()}`
    },
    refreshCaptcha () {
      if (timer) {
        clearInterval(timer)
      }
      var _this = this
      this.loadImageCaptcha()
      timer = setInterval(function () {
        _this.loadImageCaptcha()
      }, 50000)
    },
    initSocketClient () {
      axios.post('/user/qr/client', { action: 'login' }).then(res => {
        var resp = res.data
        if (resp.code === 0) {
          var data = resp.data
          this.initSocket(data.clientId, data.authCode)
          this.refreshQr(data.clientId, data.authCode)
        }
      })
    },
    showProvicy () {
        this.showModal(Provicy, this.$t('user.UserServiceAgreement'), 700, 600)
    },
    initSocket (clientId, authCode) {
      const wsuri = 'wss://www.breathmeters.com:444/qr/share/' + clientId + '/' + authCode
      this.websock = new WebSocket(wsuri)
      this.websock.onopen = this.websocketonopen
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    websocketonopen () {
       console.log('open')
    },
    websocketonmessage (msg) {
      console.log(msg)
    },
    websocketonerror () {
      console.log('error')
    },
    websocketclose () {
      console.log('close')
    },
    handleUsernameOrEmail (rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    handleSubmit (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        customActiveKey
      } = this

      state.loginBtn = true

      const validateFieldsKey = customActiveKey === 'tab1' ? ['account', 'passwd', 'captcha'] : ['mobile', 'captcha']

      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          const loginParams = { ...values }
          delete loginParams.username
          loginParams.account = values.account
          loginParams.client = this.client
          loginParams.pwd = md5(values.passwd)
          loginParams.account_type = 1
          store.dispatch('Login', loginParams)
            .then((res) => this.loginSuccess(res))
            .catch(err => this.requestFailed(err))
            .finally(() => {
              state.loginBtn = false
            })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    getCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state } = this

      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)
        }
      })
    },
    genRandomChars (len, onlyNum) {
      const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      let code = ''
      for (let i = 0; i < len; i++) {
        if (onlyNum) {
          code += chars[parseInt(Math.random() * 10)]
        } else {
          code += chars[parseInt(Math.random() * 62)]
        }
      }
      return code
    },
    loadImageCaptcha () {
       this.client = this.genRandomChars(20, false)
       this.captchaUrl = '/user/login/captcha/gen?client=' + this.client + '&color=white'
    },
    loginSuccess (res) {
      console.log(res)
      this.$router.push({ name: 'dashboard' })
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: this.$t('user.welcome'),
          description: `${timeFix()}` + this.$t('user.welcome.back')
        })
      }, 1000)
    },
    requestFailed (err) {
      this.$notification['error']({
        message: 'Error',
        description: err.msg || this.$t('user.request.network.error'),
        duration: 4
      })
      this.refreshCaptcha()
    }
  }
}
</script>

<style lang="less" scoped>
.main{
  background: rgba(255,255,255,0.9);
  padding: 10px 30px 20px 30px;
  backdrop-filter: blur(15px);
  border-radius: 8px;
  position: absolute;
  right:100px;
  width: 442px !important;

  .title{
    text-align: center;
    margin: 20px 0;
    font-weight: bold;
    font-size: 1.2rem;
  }
}
  .user-layout-login {
    label {
      font-size: 14px;
    }

    .getCaptcha {
      display: block;
      width: 100%;
      height: 40px;
    }

    .forge-password {
      font-size: 14px;
    }

    button.login-button {
      padding: 0 15px;
      font-size: 16px;
      height: 40px;
      width: 100%;
    }

  }
.user-login-other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;

  .item-icon {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.2);
    margin-left: 16px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .register {
    float: right;
    line-height: 36px;
  }
}
</style>
