<script>
import moment from 'moment'
import { getI18nLanguage } from '@/locales'

export default {
  name: 'Provicy',
  data () {
    return {
       src: null
    }
  },
  created () {
    const lang = getI18nLanguage()
    if (lang.indexOf('zh') === 0) {
      if (lang.indexOf('CN') > 0) {
        this.src = '/provicy/provicy_zh.html'
      } else {
        this.src = '/provicy/provicy_zh_tw.html'
      }
    } else {
      this.src = '/provicy/provicy_en.html'
    }
  },
  methods: {
    moment
  }
}
</script>

<template>
  <iframe style="width: 100%;overflow: auto;height:100%;" class="scroll2" :src="src">

  </iframe>
</template>

<style scoped>
  p{
    text-indent: 2em;
  }
</style>
